<template>
	<!-- 商户配置 -->
	<div class="admin-siteset-tanant el-content">
		<a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 8 }">
			<a-alert type="info">
				<template #description>
					<p style="color: #E74C3C;">
						温馨提示：网站备案查询需要去阿里云(https://market.aliyun.com/products/56928004/cmapi013094.html?spm=5176.11065268.1996646101.searchclickresult.6adb72fc5NQXjL#sku=yuncode709400000)购买相关服务。
					</p>
				</template>
			</a-alert>
			<a-form-item label="是否开启sass">
				<a-radio-group v-model:value="form.is_open_sass">
					<a-radio value="1">是</a-radio>
					<a-radio value="0">否</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="是否开启应用试用">
				<a-radio-group v-model:value="form.app_is_open_try">
					<a-radio value="1">是</a-radio>
					<a-radio value="0">否</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="试用天数"><a-input v-model:value="form.app_try_days"></a-input></a-form-item>
			<a-form-item label="用户域名审核开关">
				<a-radio-group v-model:value="form.user_domain_audit">
					<a-radio value="1">是</a-radio>
					<a-radio value="0">否</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="网站备案查询开关">
				<a-radio-group v-model:value="form.yishuju_icp_search_switch">
					<a-radio value="1">是</a-radio>
					<a-radio value="0">否</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="网站备案查询授权"><a-input v-model:value="form.yishuju_icp_search_auth_code"></a-input></a-form-item>
			<a-form-item label="PC端域名"><a-input v-model:value="form.site_pc_domain"></a-input></a-form-item>
			<a-form-item label="APP端域名"><a-input v-model:value="form.site_app_domain"></a-input></a-form-item>
			<a-form-item>
				<template #label>
					<span>APP端演示域名</span>
					<a-tooltip title="解析格式 *.h5.farmkd.com;* 代表 APPID 用于替换，如果改变则会删除原有的演示域名"><i class="ri-information-line"></i></a-tooltip>
				</template>
				<a-input v-model:value="form.site_sass_domain_app"></a-input>
			</a-form-item>
			<a-form-item>
				<template #label>
					<span>PC端演示域名</span>
					<a-tooltip title="解析格式 *.pc.farmkd.com ;* 代表 APPID 用于替换，如果改变则会删除原有的演示域名"><i class="ri-information-line"></i></a-tooltip>
				</template>
				<a-input v-model:value="form.site_sass_domain_pc"></a-input>
			</a-form-item>
			<a-form-item label="视频上传大小限制"><a-input v-model:value="form.file_max_size" addon-after="M"></a-input></a-form-item>
			<a-form-item :wrapper-col="{ offset: 3 }"><a-button type="primary" @click="submitSave">保存</a-button></a-form-item>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import saasSetModel from '@/api/saas/set';
export default {
	setup(){
		const state = reactive({
			form: {
				app_is_open_try: '0',
				app_try_days: '',
				yishuju_icp_search_auth_code: '',
				yishuju_icp_search_switch: '0',
				user_domain_audit: '0',
				site_pc_domain: '',
				site_app_domain: '',
				is_open_sass: '0',
				site_sass_domain_app: '',
				site_sass_domain_pc: '',
				file_max_size:""
			}
		})

		saasSetModel.getSiteSet(Object.keys(state.form),res=>{
			state.form = res
		})

		const submitSave = ()=>saasSetModel.saveSiteSet({keys:state.form})

		return{
			...toRefs(state),
			submitSave
		}
	}
}
</script>

<style></style>
